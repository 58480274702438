import React, { useContext, MouseEvent } from "react";

//MUI
import Button from "@mui/material/Button";

//Custom Components
import UserContext from "@/components/UserContext/UserContext";

//Types
import { ITableHeaderButtonProps } from "@/@types/ui/Table";

const TableHeaderButton: React.FC<ITableHeaderButtonProps> = (props) => {
  const userContext = useContext(UserContext);
  const { onClick, needPermission, ...otherProps } = props;

  const handleClick = function (evt: MouseEvent<HTMLButtonElement>) {
    onClick(evt);
  };

  return needPermission && userContext !== null && !userContext.hasAnyPermission(needPermission) ? null : (
    <Button
      color="primary"
      size="small"
      {...otherProps}
      onClick={handleClick}
      sx={{
        minHeight: 32,
        ml: 1,
        mr: 0
      }}
    >
      {props.children}
    </Button>
  );
};

export default TableHeaderButton;
